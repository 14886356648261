<template>
  <ModalComponent
    id="create-hardware-position-modal"
    title="Nueva posición"
    ok-title="Añadir"
    deleteText="Añadir y crear otra"
    deleteButton
    size="lg"
    @confirmed="onCreatePosition"
    @deleted="onCreatePositionAndAdd"
    @show="onShow()"
  >
    <HardwarePositionForm
      ref="hardwarePositionForm"
      :form_prop.sync="form"
      :booking="booking"
    />
  </ModalComponent>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import HardwareBookingPositionFactory from '@/domain/services/HardwareBookingPositionFactory'
import HardwareBookingFactory from '@/domain/services/HardwareBookingFactory'
import HardwarePositionForm from '@/components/Business/Project/HardwarePosition/Form/HardwarePositionForm.vue'

export default {
  name: 'CreateHardwarePositionModal',
  components: {
    ModalComponent,
    HardwarePositionForm
  },
  data () {
    return {
      form: this.form_prop,
      booking: null
    }
  },
  computed: {
    ...mapGetters({
      hardwarePosition: 'hardwarePosition/current',
      hardwareBooking: 'hardwareBooking/current',
      project: 'project/current'

    })
  },
  methods: {
    ...mapActions('hardwarePosition', ['setEmpty']),
    onShow () {
      this.setEmpty()
      this.form = HardwareBookingPositionFactory.get(this.hardwarePosition)
      this.booking = HardwareBookingFactory.get(this.hardwareBooking)
    },
    positionCreated (data) {
      this.resetBookingPositionForm()
      this.setBooking()
      this.$bvModal.hide(data.name)
      if (!data.skipSuccessDialog) {
        this.$store.dispatch('showConfirmationModal')
      } else {
        this.$bvModal.show(data.name)
      }
    },
    async onCreatePosition (name, skipSuccessDialog) {
      const valid = await this.$refs.hardwarePositionForm.$refs.hardwarePositionForm.validate()
      if (!valid) {
        return
      }
      const positionToCreate = {
        title: this.form.title,
        brandId: this.form.brand,
        productTypeId: this.form.type,
        annotation: this.form.annotation !== undefined ? this.form.annotation : null,
        quantity: parseInt(this.form.quantity, 10),
        startDate: this.form.startDate,
        endDate: this.form.endDate
      }
      const payload = {
        bookingId: this.booking.id,
        data: positionToCreate
      }
      this.$store.dispatch('hardwarePosition/create', payload)
        .then(() => {
          if (!skipSuccessDialog) {
            this.$store.dispatch('showConfirmationModal')
            this.$bvModal.hide('create-hardware-position-modal')
            const payloadBooking = {
              projectId: this.$route.params.projectId,
              id: this.booking.id
            }
            this.$store.dispatch('hardwareBooking/getOneById', payloadBooking)
          }
        })
    },
    onCreatePositionAndAdd (name) {
      this.onCreatePosition(name, true)
    }
  }
}
</script>

<style lang="less" scoped>
@import "createHardwarePositionModal";
</style>
