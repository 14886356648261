<template>
  <ModalComponent
    id="update-hardware-position-modal"
    title="Editar posición"
    ok-title="Guardar"
    size="lg"
    @confirmed="onCreatePosition"
    @show="onShow()"
    :delete-button="true"
    @deleted="onDeletePosition"
  >
    <HardwarePositionForm
      ref="hardwarePositionForm"
      :form_prop.sync="form"
      :booking="booking"
    />
    <ModalComponent
      :id="deleteModalId"
      title="¿Cancelar posición?"
      ok-title="Aceptar"
      size="lg"
      @confirmed="onCancelPosition"
      @askConfirmationFunction="onCancelPosition"
      :validation="atLeastOneReasonSelected"
    >
      <BookingCancelPositionModal
        @addOption="addOption"
        @removeOption="removeOption"
        @changeCustomReason="changeCustomReason"
        :showWarning="!atLeastOneReasonSelected"
      />
    </ModalComponent>
  </ModalComponent>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import HardwareBookingPositionFactory from '@/domain/services/HardwareBookingPositionFactory'
import HardwareBookingFactory from '@/domain/services/HardwareBookingFactory'
import HardwarePositionForm from '@/components/Business/Project/HardwarePosition/Form/HardwarePositionForm.vue'
import BookingCancelPositionModal from '@/components/BookingPosition/BookingCancelPositionModal'

export default {
  name: 'UpdateHardwarePositionModal',
  components: {
    BookingCancelPositionModal,
    ModalComponent,
    HardwarePositionForm
  },
  props: {
    bookingPositionId: {
      type: String
    }
  },
  data () {
    return {
      form: this.form_prop,
      booking: null,
      deleteReasonsSelected: [],
      customReason: ''
    }
  },
  computed: {
    ...mapGetters({
      hardwarePosition: 'hardwarePosition/current',
      hardwareBooking: 'hardwareBooking/current'
    }),
    deleteModalId () {
      return 'cancel-hardware-' + this.bookingPositionId
    },
    atLeastOneReasonSelected () {
      return this.deleteReasonsSelected.length !== 0 || this.customReason !== ''
    }
  },
  methods: {
    onShow () {
      this.form = HardwareBookingPositionFactory.get(this.hardwarePosition)
      this.form.brand = this.form.brand.id
      this.form.type = this.form.type.id
      this.booking = HardwareBookingFactory.get(this.hardwareBooking)
    },
    positionCreated (data) {
      this.resetBookingPositionForm()
      this.setBooking()
      this.$bvModal.hide(data.name)
      if (!data.skipSuccessDialog) {
        this.$store.dispatch('showConfirmationModal')
      } else {
        this.$bvModal.show(data.name)
      }
    },
    async onCreatePosition (name, skipSuccessDialog) {
      const valid = await this.$refs.hardwarePositionForm.$refs.hardwarePositionForm.validate()
      if (!valid) {
        return
      }
      const positionToUpdate = this.form
      positionToUpdate.annotation = this.form.annotation !== '' ? this.form.annotation : null
      positionToUpdate.endDate = positionToUpdate.endDate.replace('T', ' ')
      positionToUpdate.startDate = positionToUpdate.startDate.replace('T', ' ')
      positionToUpdate.quantity = Number(positionToUpdate.quantity)

      const payload = {
        bookingId: this.booking.id,
        data: positionToUpdate
      }
      this.$store.dispatch('hardwarePosition/update', payload)
        .then(() => {
          if (!skipSuccessDialog) {
            const payloadBooking = {
              projectId: this.$route.params.projectId,
              id: this.booking.id
            }
            this.$store.dispatch('hardwareBooking/getOneById', payloadBooking)
            this.$store.dispatch('showConfirmationModal')
            this.$bvModal.hide('update-hardware-position-modal')
          }
        })
    },
    onDeletePosition () {
      this.$bvModal.show(this.deleteModalId)
    },
    onCancelRemove () {
      this.$bvModal.hide(this.deleteModalId)
    },
    async onCancelPosition () {
      if (this.deleteReasonsSelected.length === 0 && this.customReason === '') {
        return false
      }

      const data = {
        hardwareBookingId: this.booking.id,
        hardwareBookingPositionId: this.hardwarePosition.id,
        customDeletedReason: this.customReason,
        deletedReasonsIds: this.deleteReasonsSelected.map(element => {
          return { id: element }
        })
      }

      await this.$store.dispatch('hardwarePosition/addDeleteReasons', data)
        .then(() => this.onConfirmRemove())
    },
    onConfirmRemove () {
      this.lockScreen()
      const payload = {
        hardwareBookingId: this.booking.id,
        hardwareBookingPositionId: this.form.id,
        bookingDeletedReasonId: this.deleteReasonsSelected[0]
      }
      this.$store.dispatch('hardwarePosition/delete', payload)
        .then(() => {
          this.onCancelRemove()
          this.$bvModal.hide('update-hardware-position-modal')
          const payloadBooking = {
            projectId: this.$route.params.projectId,
            id: this.booking.id
          }
          this.$store.dispatch('hardwareBooking/getOneById', payloadBooking)
          this.$store.dispatch('showConfirmationModal')
          this.unlockScreen()
        })
    },
    addOption (optionId) {
      this.deleteReasonsSelected.push(optionId)
    },
    removeOption (optionId) {
      const index = this.deleteReasonsSelected.indexOf(optionId)
      if (index > -1) {
        this.deleteReasonsSelected.splice(index, 1)
      }
    },
    changeCustomReason (customReason) {
      this.customReason = customReason.target.value
    }
  }
}
</script>

<style lang="less" scoped>
@import "updateHardwarePositionModal";
</style>
