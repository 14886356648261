<template>
  <validation-observer ref="bookingPositionForm" v-slot="{ handleSubmit }">
    <b-form @submit.stop.prevent="handleSubmit(checkForm)" autocomplete="off" novalidate>
      <b-row>
        <b-col>
          <h6>Por favor, indica el motivo por el que deseas cancelar la posición.</h6>
          <span v-if="showWarning" class="warning">Selecciona al menos una razón</span>
          <b-form-group id="reasons" label-for="input-reasons">
            <b-form-checkbox
              v-for="deleteReason in deleteReasons"
              :key="deleteReason.id"
              :id="deleteReason.id"
              class="avify-checkbox filter reason-checkbox"
              name="deleteReason"
              @change="updateOption($event, deleteReason.id)"
            >
              {{ deleteReason.name }}
            </b-form-checkbox>
            <p>
              Otro:
              <b-form-textarea
                id="customReason"
                name="customReason"
                v-model="customReason"
                @keyup="updateChangeReason"
                placeholder="Otro motivo"
                rows="3"
              />
            </p>
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
export default {
  name: 'BookingCancelPositionModal',
  props: {
    showWarning: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    deleteReasonsOptions () {
      return this.deleteReasons.map(elem => {
        return {
          text: elem.name,
          value: elem
        }
      })
    }
  },
  data () {
    return {
      deleteReasons: [],
      customReason: ''
    }
  },
  async created () {
    this.deleteReasons = await this.$store.dispatch('technicianPosition/getDeleteReasons')
  },
  methods: {
    updateOption (event, reasonId) {
      if (event) {
        this.$emit('addOption', reasonId)
      } else {
        this.$emit('removeOption', reasonId)
      }
    },
    updateChangeReason (value) {
      this.$emit('changeCustomReason', value)
    }
  }
}
</script>
<style lang="less" scoped>
  @import "../../style/text";

  * {
    font-family: @font_primary;
  }

  form {
    h6 {
      font-family: @font_fira;
    }
  }

  .reason-checkbox {
    margin: 1rem 0;
  }

  .customReason {
    width: 20%;
  }

  .warning {
    font-size: 14px;
    color: red;
  }
</style>
