<template>
  <validation-observer ref="hardwarePositionForm" v-slot="{ handleSubmit }">
    <b-form v-if="position" @submit.stop.prevent="handleSubmit(checkForm)" autocomplete="off" novalidate>
    <b-row>
      <b-col>
        <h6>Título</h6>
        <validation-provider
          vid="title"
          name="title"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="title" label-for="input-title">
            <b-form-input
              id="title"
              type="text"
              name="title"
              :state="getValidationState(validationContext)"
              placeholder="Escribe título de la posición"
              v-model="position.title"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Fecha</h6>
        <v-date-picker
          v-model="dateRange"
          color="indigo"
          is-dark
          is-range
          is-expanded
          locale="ES-es"
          :popover="{ placement: 'auto' }"
          @input="dateSelected"
          :available-dates='{ start: booking.getEventStartAt, end: booking.getEventEndAt }'
          mode="dateTime"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <b-row>
              <b-col>
                <validation-provider
                  vid="startDate"
                  name="fecha de inicio"
                  rules="required"
                  v-slot="validationContext"
                >
                  <b-form-group class="m-0" id="startDate-group" label-for="input-start-date">
                    <b-form-input
                      id="startDate"
                      name="start-date"
                      placeholder="Fecha inicio"
                      :state="getValidationState(validationContext)"
                      readonly
                      :value="inputValue.start"
                      v-on="inputEvents.start"
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
              <b-col>
                <validation-provider
                  vid="endDate"
                  name="fecha de final"
                  rules="required|todayOn"
                  v-slot="validationContext"
                >
                  <b-form-group class="m-0" id="endDate-group" label-for="input-end-date">
                    <b-form-input
                      id="endDate"
                      name="end-date"
                      placeholder="Fecha final"
                      readonly
                      :state="getValidationState(validationContext)"
                      :value="inputValue.end"
                      v-on="inputEvents.end"
                    />
                    <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
                  </b-form-group>
                </validation-provider>
              </b-col>
            </b-row>
          </template>
        </v-date-picker>
        <small class="placeholder font-weight-light d-inline-block mb-3">
          * Solo se pueden seleccionar rango de fechas
        </small>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="form-suggestions-title">
          <h6>Marca</h6>
          <small>
            ¿No encuentras una marca?
            <strong @click="onAddBrandSuggestion">
              Sugiere una nueva marca
            </strong>
          </small>
        </div>
        <validation-provider
          vid="brand"
          name="marca"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group id="brand" label-for="input-event-type">
            <b-form-select
              v-model="position.brand"
              :options="brandOptions"
              :state="getValidationState(validationContext)"
            >
            <template #first>
              <b-form-select-option :value="null" disabled>Elige de la lista</b-form-select-option>
            </template>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <div class="form-suggestions-title">
          <h6>Tipo de equipo</h6>
          <small>
            ¿No encuentras un equipo?
            <strong @click="onAddProductTypeSuggestion">
              Sugiere un nueva equipo
            </strong>
          </small>
        </div>
        <validation-provider
          vid="type"
          name="tipo de equipo"
          rules="required"
          v-slot="validationContext"
        >
          <b-form-group id="type" label-for="input-event-type">
            <b-form-select
              v-model="position.type"
              :options="productTypeOptions"
              :state="getValidationState(validationContext)"
            >
            <template #first>
              <b-form-select-option :value="null" disabled>Elige de la lista</b-form-select-option>
            </template>
            </b-form-select>
            <b-form-invalid-feedback>
              {{ validationContext.errors[0] }}
            </b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Cantidad</h6>
        <validation-provider
          vid="quantity"
          name="cantidad"
          :rules="{ required: true }"
          v-slot="validationContext"
        >
          <b-form-group id="quantity" label-for="input-quantity">
            <b-form-input
              id="quantity"
              type="number"
              name="quantity"
              :state="getValidationState(validationContext)"
              placeholder="Escribe la cantidad de este elemento que necesitas"
              v-model="position.quantity"
            ></b-form-input>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <h6>Anotaciones</h6>
        <validation-provider
          vid="annotation"
          name="annotation"
          :rules="{ required: false }"
          v-slot="validationContext"
        >
          <b-form-group id="annotation" label-for="input-annotation">
            <b-form-textarea
              id="annotation"
              name="annotation"
              v-model="position.annotation"
              :state="getValidationState(validationContext)"
              placeholder="Escribe anotaciones extras (Opcional)"
              rows="3"
              max-rows="6"
            ></b-form-textarea>
            <b-form-invalid-feedback>{{ validationContext.errors[0] }}</b-form-invalid-feedback>
          </b-form-group>
        </validation-provider>
      </b-col>
    </b-row>
    <AddSuggestionModal
      :id="addSuggestionModalId"
      :type="suggestionItem"
      :suggestionText="suggestionText"
    >
    </AddSuggestionModal>
  </b-form>
  </validation-observer>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import AddSuggestionModal from '@/components/Modal/AddSuggestionModal'

export default {
  name: 'HardwarePositionForm',
  components: {
    AddSuggestionModal
  },
  props: {
    form_prop: {
      type: Object
    },
    booking: {
      type: Object,
      require: true
    }
  },
  data () {
    const dateRange = {
      start: null,
      end: null
    }
    return {
      position: this.form_prop,
      dateRange: dateRange,
      addSuggestionModalId: '',
      suggestionItem: '',
      suggestionText: ''
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapGetters({ brands: 'hardwareBrand/all' }),
    ...mapGetters({ productTypes: 'productType/all' }),
    brandOptions () {
      return this.brands.map(brand => {
        return {
          text: brand.name,
          value: brand.id
        }
      })
    },
    productTypeOptions () {
      return this.productTypes.map(pt => {
        return {
          text: pt.name,
          value: pt.id
        }
      })
    }
  },
  watch: {
    form_prop (value) {
      this.position = value
    }
  },
  methods: {
    ...mapActions('hardwareBrand', { getAllBrands: 'getAll' }),
    ...mapActions('productType', { getAllProductTypes: 'getAll' }),
    loadData () {
      this.getAllBrands()
      this.getAllProductTypes()
      this.dateRange = {
        start: this.position.getStartDate ? this.position.getStartDate : null,
        end: this.position.getEndDate ? this.position.getEndDate : null
      }
    },
    dateSelected (date) {
      this.position.startDate = this.formatDate(date.start)
      this.position.endDate = this.formatDate(date.end)
    },
    formatDate (date) {
      return date.getFullYear() + '-' +
        this.lpad(date.getMonth() + 1) + '-' +
        this.lpad(date.getDate()) + ' ' +
        this.lpad(date.getHours()) + ':' +
        this.lpad(date.getMinutes()) + ':' +
        this.lpad(date.getSeconds())
    },
    lpad (value) {
      return String(value).padStart(2, '0')
    },
    onAddBrandSuggestion () {
      this.addSuggestionModalId = 'add-suggestion-marca'
      this.suggestionItem = 'marca'
      this.suggestionText = 'Sugiere una nueva'
      this.$bvModal.show(this.addSuggestionModalId)
    },
    onAddProductTypeSuggestion () {
      this.addSuggestionModalId = 'add-suggestion-equipo'
      this.suggestionItem = 'equipo'
      this.suggestionText = 'Sugiere un nuevo'
      this.$bvModal.show(this.addSuggestionModalId)
    }
  }
}
</script>

<style lang="less" scoped>
@import "hardwarePositionForm";
</style>
