<template>
  <b-modal
    id="broadcast-booking"
    modal-class="user-information-modal booking-modals centered"
    centered
    hide-header
    hide-footer
  >
    <b-row>
      <b-col>
        <h4>¿Publicar y difundir el Booking?</h4>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        Los propietarios de equipamientos que se ajusten a tus solicitudes recibirán una notificación para aplicar al Booking.
      </b-col>
    </b-row>
    <b-row class="buttons">
      <b-col>
        <b-button class="float-right" variant="secondary" @click="onDiscardBooking">Descartar</b-button>
      </b-col>
      <b-col>
        <b-button class="float-left" variant="primary" @click="$emit('onBroadcastBooking')">Publicar</b-button>
      </b-col>
    </b-row>
  </b-modal>
</template>

<script>

export default {
  name: 'BroadcastHardwareBookingModal',
  methods: {
    onDiscardBooking () {
      this.$bvModal.hide('broadcast-booking')
    }
  }
}
</script>

<style lang="less" scoped>
@import "broadcastHardwareBookingModal";
</style>
