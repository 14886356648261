<template>
  <ModalComponent
    id="update-hardware-booking-modal"
    title="Editar booking"
    ok-title="Siguiente"
    size="lg"
    @confirmed="onCreateBooking"
    @show="onShow()"
  >
    <HardwareBookingForm
      ref="hardwareBookingForm"
      :form_prop.sync="form"
    />
  </ModalComponent>
</template>

<script>
import { mapGetters } from 'vuex'
import ModalComponent from '@/components/Modal/ModalComponent.vue'
import HardwareBookingForm from '@/components/Business/Project/HardwareBooking/Form/HardwareBookingForm.vue'
import HardwareBookingFactory from '@/domain/services/HardwareBookingFactory'

export default {
  name: 'UpdateHardwareBookingModal',
  components: {
    ModalComponent,
    HardwareBookingForm
  },
  data () {
    return {
      form: this.form_prop
    }
  },
  computed: {
    ...mapGetters({ hardwareBooking: 'hardwareBooking/current' })
  },
  methods: {
    onShow () {
      this.form = HardwareBookingFactory.get(this.hardwareBooking)
    },
    async onCreateBooking () {
      const valid = await this.$refs.hardwareBookingForm.$refs.hardwareBookingForm.validate()
      if (!valid) {
        return
      }
      const bookingToUpdate = this.form
      delete bookingToUpdate.broadcasted
      delete bookingToUpdate.createdAt
      const payload = {
        projectId: this.$route.params.id,
        data: bookingToUpdate
      }
      this.$store.dispatch('hardwareBooking/update', payload)
        .then(() => {
          this.$bvModal.hide('update-hardware-booking-modal')
          this.$store.dispatch('showConfirmationModal')
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import 'updateHardwareBookingModal';
</style>
