<template>
 <b-row>
    <b-col>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column line-space">
          <h6 class="font-weight-bold d-flex align-items-center">
            <span class="mr-2">{{ bookingPosition.title }}</span>
            <AvifyIcon class="ml-2" />
            <JobantTalentIcon class="ml-2" v-if="bookingPosition.getJobandtalent" />
          </h6>
          <span class="text">
            Fecha:
            <span class="pink font-weight-bold">{{ bookingPosition.getEventDate }}</span>
          </span>
          <span class="text">
            Hora:
            <span class="pink font-weight-bold">{{ bookingPosition.getOnlyTime }}</span>
          </span>

          <span class="text">
            Cantidad:
            <span class="pink font-weight-bold">
              {{ bookingPosition.getQuantity }}
            </span>
          </span>

          <span class="text" v-if="bookingPosition.annotation">
            Anotaciones:
            <span class="pink font-weight-bold">{{ bookingPosition.annotation }}</span>
          </span>
        </div>
        <div>
          <b-button
            v-b-modal="bookingPosition.id"
            class="btn float-right btn-outline"
            v-if="editable"
            v-b-modal.update-hardware-position-modal
            @click="onEditPosition"
          >
            Editar
          </b-button>
          <UpdateHardwarePositionModal :bookingPositionId = "bookingPosition.id" />
        </div>
      </div>
    </b-col>
    <b-col cols="5" v-if="booking.getBroadcasted">
      <div class="position-side">
        <div v-if="bookingPosition.getOpened" class="opened-position text-nowrap">
          <span class="d-none d-sm-inline-block m-0">posición abierta</span>
          <img
            alt="Posición abierta"
            :src="require('@/assets/icons/booking-opened.svg')"
          >
        </div>
        <div v-if="!bookingPosition.getOpened" class="closed-position text-nowrap">
          <span class="d-none d-sm-inline-block m-0">posición cerrada</span>
          <img
            alt="Posición cerrada"
            :src="require('@/assets/icons/booking-closed.svg')"
          >
        </div>
        <div class="contacted-technicians-technicians" v-on:click="goToPosition">
          <span>{{ totalContactedTechnicians }}</span>
          <img
            :src="require('@/assets/icons/inbox.svg')"
          >
        </div>
      </div>
    </b-col>
  </b-row>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex'
import AvifyIcon from '@/components/SmallLogo/Avify'
import UpdateHardwarePositionModal from '@/components/Business/Project/HardwarePosition/Update/UpdateHardwarePositionModal.vue'
import { formatPrice } from '@/domain/services/FormatPrice'

export default {
  name: 'SummaryHardwarePosition',
  components: {
    AvifyIcon,
    UpdateHardwarePositionModal
  },
  props: {
    bookingPosition: {
      type: Object
    }
  },
  data () {
    return {
      totalContactedTechnicians: 0,
      formatPrice
    }
  },
  created () {
    this.loadData()
  },
  computed: {
    ...mapGetters({ booking: 'hardwareBooking/current' }),
    editable () {
      return this.booking !== null && this.booking.getOpened && !this.booking.getBroadcasted
    }
  },
  methods: {
    ...mapMutations('hardwarePosition', ['setCurrent']),
    ...mapActions('hardwarePosition', ['getTotalContactedHardwareFromBusiness']),
    async loadData () {
      const payload = {
        bookingId: this.booking.id,
        bookingPositionId: this.bookingPosition.id
      }
      this.totalContactedTechnicians = await this.getTotalContactedHardwareFromBusiness(payload)
    },
    onEditPosition () {
      this.setCurrent(this.bookingPosition)
    },
    goToPosition () {
      this.$router.push({
        name: 'detail-hardware-position',
        params: {
          projectId: this.$route.params.projectId,
          hardwareBookingId: this.$route.params.hardwareBookingId,
          hardwarePositionId: this.bookingPosition.id
        }
      })
    }
  }
}
</script>

<style lang="less" scoped>
@import "summaryHardwarePosition";
</style>
