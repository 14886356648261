<template>
  <ProjectLayout
    :displayedCard="'opened-hardware-booking'"
  >
    <b-card
      align="left"
      class="view-booking side-card colored"
      v-if="booking"
    >
      <b-row>
        <b-col><h4>{{ booking.getName }}</h4></b-col>
        <b-col
          class="text-right"
          v-if="booking !== null && booking.getOpened && !booking.getBroadcasted"
        >
          <span class="edit-booking" v-b-modal.update-hardware-booking-modal>
            <img :src="require('@/assets/icons/edit_card.svg')">
          </span>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="6" lg="3">
          <TitleValue title="ID del booking" :value="booking.getCode"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Creado por" :value="booking.getCreatedBy"/>
        </b-col>
        <div class="d-block-sm w-100" />
        <b-col cols="6" lg="3">
          <TitleValue title="Fecha de creación" :value="getDate(booking.getCreatedAt)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Estado" :value="getBookingStatus(booking)"/>
        </b-col>
      </b-row>
    </b-card>
    <b-card
      align="left"
      class="view-booking side-card colored"
      v-if="booking"
    >
      <b-row>
        <b-col><h4>Detalles del evento</h4></b-col>
        <b-col
          class="text-right"
          v-if="booking !== null && booking.getOpened && !booking.getBroadcasted"
        >
          <span class="edit-booking" v-b-modal.update-hardware-booking-modal>
            <img :src="require('@/assets/icons/edit_card.svg')">
          </span>
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="6" lg="3">
          <TitleValue title="Fecha del evento" :value="getEventDates(booking)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Hora del evento" :value="getEventHours(booking)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Lugar del evento" :value="getLocation(booking.getLocation)"/>
        </b-col>
        <b-col cols="6" lg="3">
          <TitleValue title="Tipo de evento" :value="booking.getEventType.name"/>
        </b-col>
      </b-row>
    </b-card>
    <ListHardwarePosition />
    <b-row class="button-wrapper">
      <b-col>
        <b-button
          v-if="canBePublished"
          v-b-modal.broadcast-booking
          class="float-right broadcast-booking"
          variant="primary">
            Publicar booking
        </b-button>
        <b-button
          v-if="booking !== null && booking.getBroadcasted && booking.getOpened"
          @click="$bvModal.show('close-booking')"
          class="float-right broadcast-booking"
          variant="primary">
            Cerrar Booking
        </b-button>
      </b-col>
    </b-row>
    <UpdateHardwareBookingModal />
    <BroadcastHardwareBookingModal
      @onBroadcastBooking="onBroadcastBooking"
    />
    <CloseHardwareBookingModal
      @onCloseBooking="onCloseBooking"
    />
  </ProjectLayout>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import ProjectLayout from '@/views/project/layout/ProjectLayout.vue'
import TitleValue from '@/components/Bookings/TitleValue.vue'
import ListHardwarePosition from '@/components/Business/Project/HardwarePosition/List/ListHardwarePosition.vue'
import UpdateHardwareBookingModal from '@/components/Business/Project/HardwareBooking/Update/UpdateHardwareBookingModal.vue'
import BroadcastHardwareBookingModal from '@/components/Business/Project/HardwareBooking/BroadcastBooking/BroadcastHardwareBookingModal.vue'
import CloseHardwareBookingModal from '@/components/Business/Project/HardwareBooking/CloseBooking/CloseHardwareBookingModal.vue'

export default {
  name: 'DetailHardwareBooking',
  components: {
    ProjectLayout,
    TitleValue,
    ListHardwarePosition,
    UpdateHardwareBookingModal,
    BroadcastHardwareBookingModal,
    CloseHardwareBookingModal
  },
  created () {
    const payload = {
      projectId: this.$route.params.projectId,
      id: this.$route.params.hardwareBookingId
    }
    this.getBookingById(payload)
    this.getAllPositions(this.$route.params.hardwareBookingId)
  },
  computed: {
    ...mapGetters({ booking: 'hardwareBooking/current' }),
    canBePublished () {
      return this.booking !== null &&
        this.booking.getOpened &&
        !this.booking.getBroadcasted &&
        this.booking.positions.length > 0
    }
  },
  methods: {
    ...mapActions('hardwareBooking', {
      updateBooking: 'update',
      getBookingById: 'getOneById'
    }),
    ...mapActions('hardwarePosition', { getAllPositions: 'getAll' }),
    getDate (date) {
      return this.lpad(date.getDate()) + '/' +
        this.lpad((date.getMonth() + 1)) + '/' +
        date.getFullYear()
    },
    lpad (data) {
      return String(data).padStart(2, '0')
    },
    getBookingStatus (booking) {
      if (booking.getOpened) {
        return 'Abierto'
      }
      return 'Cerrado'
    },
    getEventDates (booking) {
      const start = booking.getEventStartAt
      const end = booking.getEventEndAt
      if (
        start.getMonth() === end.getMonth() &&
        start.getFullYear() === end.getFullYear()
      ) {
        return this.getShortDates(start, end)
      }
      return this.getLongDates(start, end)
    },
    getShortDates (start, end) {
      return this.lpad(start.getDate()) + ' - ' +
        this.lpad(end.getDate()) + ' de ' +
        this.getMonth(start.getMonth()) + ' ' +
        start.getFullYear()
    },
    getLongDates (start, end) {
      return this.lpad(start.getDate()) + '/' +
        this.lpad(start.getMonth() + 1) + '/' +
        start.getFullYear() + ' al ' +
        this.lpad(end.getDate()) + '/' +
        this.lpad(end.getMonth() + 1) + '/' +
        end.getFullYear()
    },
    getMonth (jsMonth) {
      return [
        'enero',
        'febrero',
        'marzo',
        'abil',
        'mayo',
        'junio',
        'julio',
        'agosto',
        'septiembre',
        'octubre',
        'noviembre',
        'diciembre'
      ][jsMonth]
    },
    getEventHours (booking) {
      const start = booking.getEventStartAt
      const end = booking.getEventEndAt
      return this.lpad(start.getHours()) + ':' + this.lpad(start.getMinutes()) +
        ' - ' +
        this.lpad(end.getHours()) + ':' + this.lpad(end.getMinutes())
    },
    getLocation (location) {
      return location.name + ' (' + location.administrative + ')'
    },
    onBroadcastBooking () {
      const payload = {
        projectId: this.$route.params.projectId,
        data: this.booking
      }
      this.$store.dispatch('hardwareBooking/broadcastBooking', payload)
        .then(() => {
          this.$bvModal.hide('broadcast-booking')
          this.$store.dispatch('showConfirmationModal')
        })
        .catch(error => {
          this.$bvModal.hide('broadcast-booking')
          console.log(error)
        })
    },
    onCloseBooking () {
      const editableBooking = this.booking
      editableBooking.opened = false
      delete editableBooking.createdAt
      delete editableBooking.broadcasted
      const payload = {
        projectId: this.$route.params.projectId,
        data: editableBooking
      }
      this.updateBooking(payload)
        .then(() => {
          this.$bvModal.hide('close-booking')
          this.$store.dispatch('showConfirmationModal')
        })
        .catch(error => {
          this.$bvModal.hide('close-booking')
          console.log(error)
        })
    }
  }
}
</script>

<style lang="less" scoped>
@import "detailHardwareBooking";
</style>
